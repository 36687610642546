import { MatPaginatorIntl } from '@angular/material/paginator';

function transform(value: any, casas?: number) {
  let cc = 2;

  if (casas == 0 || casas) {
    cc = casas;
  }

  value = parseFloat(value);
  let n = value,
    c = cc,
    d = ',',
    t = '.',
    s = n < 0 ? '-' : '',
    i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + '',
    j: any;
  j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c > 0
      ? d +
        Math.abs(n - parseInt(i))
          .toFixed(c)
          .slice(2)
      : '')
  );
}

const dutchRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) {
    return `0 de ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

  let len = transform(length, 0);

  return `${startIndex + 1} - ${endIndex} de ${len}`;
};

export function getTranslatePaginacaoIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Itens por pagina:';
  paginatorIntl.nextPageLabel = 'Proxima pagina';
  paginatorIntl.previousPageLabel = 'Voltar pagina';
  paginatorIntl.firstPageLabel = 'Primeira pagina';
  paginatorIntl.lastPageLabel = 'Ultima pagina';
  paginatorIntl.getRangeLabel = dutchRangeLabel;

  return paginatorIntl;
}
