import { Deserializable } from './deserializable.model';

export enum ModulesTypes {
  None = 0,
  ORÇAMENTOS = 1,
  CLIENTES = 2,
  FORNECEDORES= 3
}
export class ModulesModel implements Deserializable {
  id!: ModulesTypes;
  name!: string;
  icon!: string;

  deserialize(input: any): this {
    input.id = +input.id;
    Object.assign(this, input);
    return this;
  }

  // static getModulosAdmin():ModulesModel[]{
  //   let modulosAdmin:ModulesModel[] = [];
  //   let moduloClientes = new ModulesModel();
  //   moduloClientes.id = 2;
  //   moduloClientes.name = "CLIENTES";
  //   let moduloFornecedores = new ModulesModel();
  //   moduloFornecedores.id = 3;
  //   moduloFornecedores.name = "FORNECEDORES";
  //   let moduloOrçamentos = new ModulesModel();
  //   moduloOrçamentos.id = 1;
  //   moduloOrçamentos.name = "ORÇAMENTOS";
  //   modulosAdmin.push(moduloClientes,moduloFornecedores,moduloOrçamentos);
  //   return modulosAdmin;
  // }

  // static getModulosFornecedor():ModulesModel[]{
  //   let modulosAdmin:ModulesModel[] = [];

  //   let moduloOrçamentos = new ModulesModel();
  //   moduloOrçamentos.id = 1;
  //   moduloOrçamentos.name = "ORÇAMENTOS";
  //   modulosAdmin.push(moduloOrçamentos);
  //   return modulosAdmin;
  // }

  // static getModulosCliente():ModulesModel[]{
  //   let modulosAdmin:ModulesModel[] = [];
  //   let moduloOrçamentos = new ModulesModel();
  //   moduloOrçamentos.id = 1;
  //   moduloOrçamentos.name = "ORÇAMENTOS";
  //   modulosAdmin.push(moduloOrçamentos);
  //   return modulosAdmin;
  // }
  
}
