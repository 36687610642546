import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';

export interface Breadcrumb {
  title: string;
  page: string | any;
  queryParams: Params | null;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  title = '';
  breadcrumbs: Breadcrumb[] = [];

  rootRoute = '';

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    let url = this.cleanUrl(this.router.url);
    url = url.replace(new RegExp(/\./, 'g'), '/');

    this.rootRoute = url.split('/')[0];

    this.setBreadcrumbs();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setBreadcrumbs();
      }
    });
  }

  private setBreadcrumbs() {
    let root: ActivatedRoute = this.route.root;
    this.breadcrumbs = this.getBreadcrumbs(root, '/' + this.rootRoute);

    if (this.breadcrumbs.length > 0) {
      this.title = this.breadcrumbs[0].title;
      this.breadcrumbs.splice(0, 1);
    }
  }

  private getBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    let ROUTE_DATA_BREADCRUMB = 'breadcrumb';

    let children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (let child of children) {
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      let routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');

      url += `/${routeURL}`;

      const breadcrumb: Breadcrumb = {
        title: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        page: url,
        queryParams: null,
      };

      breadcrumbs.push(breadcrumb);

      return this.getBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }

  cleanUrl(url: string): string {
    if (url.charAt(0) == '/') {
      url = url.substr(1);
    }

    let finalUrl = url.replace(/\//g, '.');
    if (finalUrl.indexOf('?') !== -1) {
      finalUrl = finalUrl.substring(0, finalUrl.indexOf('?'));
    }

    return finalUrl;
  }
}
