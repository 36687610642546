<!-- begin:: Page -->
<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
	<!-- begin:: Header Mobile -->
	<app-header-mobile>
	</app-header-mobile>
	<!-- end:: Header Mobile -->

	<div class="d-flex flex-column flex-root">
		<!-- begin::Body -->
		<div class="d-flex flex-row flex-column-fluid page">
			<!-- begin:: Aside Left -->
			<ng-container>
				<app-aside-left>
				</app-aside-left>
			</ng-container>
			<!-- end:: Aside Left -->

			<!--begin::Wrapper-->
			<div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
				<!-- begin:: Header -->
				<app-header>
				</app-header>
				<!-- end:: Header -->

				<!-- begin:: Content -->
				<div class="content d-flex flex-column flex-column-fluid" id="kt_content" [ngClass]="contentClasses">
					<!-- begin:: Content Body -->
					<!--begin::Entry-->
					<div appContentAnimate class="d-flex flex-column-fluid">
						<!--begin::Container-->
						<div [ngClass]="contentContainerClasses">
							<router-outlet></router-outlet>
							<!--end::Container-->
						</div>
					</div>
					<!--end::Entry-->
					<!-- end:: Content Body -->

				</div>
				<!-- end:: Content -->

				<app-footer>
				</app-footer>
			</div>
			<!--end::Wrapper-->
		</div>
		<!-- end:: Body -->

		<app-scroll-top>
		</app-scroll-top>
	</div>
</ng-container>
<!-- end:: Page -->

<ng-template #blankLayout>
	<div class="d-flex flex-column flex-root">
		<router-outlet></router-outlet>
	</div>
</ng-template>