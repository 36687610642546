import { Deserializable } from './deserializable.model';
import { ModulesModel } from './modules.model';

export class Permissao implements Deserializable {
  id!: number;
  nome!: string;
  

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
