import { CentroCusto } from './centro-custo.model';
import { Deserializable } from './deserializable.model';
import { Perfil } from './perfil.model';

export class User implements Deserializable {
  id!: number;

  login!: string;
  nomeFantasia!: string;
  idPerfil!:number;
  type!:number;
  perfil:Perfil = new Perfil();
  centroCustos:CentroCusto[] = []
  deserialize(input: any): this {
    input.centroCustos = input.centroCustos.map((mod: any) =>
      new CentroCusto().deserialize(mod)
    );
    Object.assign(this, input);
    return this;
  }
}
