import { createReducer, on } from '@ngrx/store';
import { User } from '../../models/user.model';
import { clear, set } from './user.actions';

const INITIAL_STATE = new User();

const _userReducer = createReducer(
  INITIAL_STATE,
  on(set, (state, { user }) => user),
  on(clear, (state) => new User())
);

export function userReducer(state: any, action: any) {
  return _userReducer(state, action);
}
