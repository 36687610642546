import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PersistenceService, StorageType } from 'angular-persistence';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { clear, set } from 'src/app/store/user/user.actions';
import { environment } from 'src/environments/environment';
import { User } from './../../../../models/user.model';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private persistenceService: PersistenceService,
    private permissionsService: NgxPermissionsService,
    private store: Store<{ user: User }>
  ) {}

  
  login(usuario: string, senha: string): Observable<void> {
    return this.http
      .post<{ token: string; usuario: User }>(environment.serverUrl+'login/authentication', {
        usuario,
        senha,
      })
      .pipe(
        map((res) => {
         res.usuario.type = res.usuario.idPerfil;
          this.persistenceService.set('token', res.token, {
            type: StorageType.LOCAL,
          });
          this.persistenceService.set('user', res.usuario, {
            type: StorageType.LOCAL,
          });
          this.setPermissions(res.usuario);
        })

      );
  }
  // login(usuario: string, senha: string): Observable<void> {
  //   return this.http
  //     .post<{ token: string; usuario: User }>(environment.serverUrl+'login/authentication', {
  //       usuario,
  //       senha,
  //     })
  //     .pipe(
  //       map((res) => {
  //        res.usuario.idPerfil = res.usuario.idPerfil;
  //         this.persistenceService.set('token', res.token, {
  //           type: StorageType.LOCAL,
  //         });
  //         this.persistenceService.set('user', res.usuario, {
  //           type: StorageType.LOCAL,
  //         });
  //         this.setPermissions(res.usuario);
  //       })

  //     );
  // }

  load(): void {
    const user = this.persistenceService.get('user', StorageType.LOCAL);

    if (user) {
      this.setPermissions(user);
    }
  }

  setPermissions(user: User): void {
    this.loadType(user);
  }
  loadType(user: User): void {
    let roles: string[] = [];

    if (user) {
      switch (user.idPerfil) {
        case 1:
          roles = ['ADMINISTRADOR'];
          break;
        case 2:
          roles = ['FORNECEDOR'];
          break;
        case 3:
          roles = ['CLIENTE'];
          break;
      }

      /*for (const mod of user.modules) {
        roles.push('module' + mod.id);
      }*/
    }
    this.permissionsService.loadPermissions(roles);
    this.store.dispatch(set({ user }));
  }
  // loadType(user: User): void {
  //   let roles: string[] = [];

  //   if (user) {
  //     roles = user.perfil.permissoes.map((perm)=>perm.nome.replace(/\s/g, ""));
  //     roles.push(user.perfil.tipo!.nome!.replace(/\s/g, ""));

  //   }
  //   this.permissionsService.loadPermissions(roles);
  //   this.store.dispatch(set({ user }));
  // }

  logout(): void {
    this.permissionsService.flushPermissions();
    this.persistenceService.remove('user', StorageType.LOCAL);
    this.store.dispatch(clear());
  }

  requestPassword(login: string, email: string): Observable<any> {
    return this.http.post<User>('auth/forgotPassword', {
      login,
      email,
    });
  }
}
