<!--begin::User-->
<div ngbDropdown placement="bottom-right" class="dropdown dropdown-fluid">
  <!--begin::Toggle-->
  <div ngbDropdownToggle class="topbar-item">
    <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
      
      <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Olá,</span>
      <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{(user$ |
        async)?.nomeFantasia}}</span>
    </div>
  </div>
  <!--end::Toggle-->

  <!--begin::Dropdown-->
  <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
    <ng-container>
      <!--begin::Header-->
      <div class="d-flex flex-wrap p-8 rounded-top flex-center" style="background-color: #131f26">
        <div class="d-flex flex-column text-center">
          <div class="text-white m-0 flex-grow-1 font-size-h5">
            {{(user$ | async)?.nomeFantasia}}
          </div>
          <span class="font-size-sm text-white mt-2">
            <ng-container *ngIf="(user$ | async)?.type == 1">
              Administrador
            </ng-container>
            <ng-container *ngIf="(user$ | async)?.type == 2">
              Cliente
            </ng-container>
          </span>
        </div>
      </div>
      <!--end::Header-->
    </ng-container>
    <!--begin::Nav-->
    <div class="navi navi-spacer-x-0 pt-5">
      <!--begin::Item-->
      <a ngbDropdownItem routerLink="profile" href="javascript:void(0);" class="navi-item px-8">
        <div class="navi-link">
          <div class="navi-icon mr-2">
            <i class="fas fa-user"></i>
          </div>
          <div class="navi-text">
            <div class="font-weight-bold">
              Perfil
            </div>
            <div class="text-muted">
              Dados referentes ao seu cadastro
            </div>
          </div>
        </div>
      </a>
      <a *ngIf="(user$ | async)?.idPerfil == 1" ngbDropdownItem routerLink="profile" href="javascript:void(0);"
        class="navi-item px-8">
        <div class="navi-link">
          <div class="navi-icon mr-2">
            <i class="fas fa-cogs"></i>
          </div>
          <div class="navi-text">
            <div class="font-weight-bold">
              Configurações
            </div>
            <div class="text-muted">
              Configurações gerais do sistema
            </div>
          </div>
        </div>
      </a>
      <!--end::Item-->
      <!--begin::Footer-->
      <div class="navi-separator mt-3"></div>
      <div class="navi-footer  px-8 py-5">
        <a href="javascript:void(0);" (click)="logout()" class="btn btn-plusfrota font-weight-bold">Sair</a>
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Nav-->
  </div>
  <!--end::Dropdown-->
</div>
<!--end::User-->