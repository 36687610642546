import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from './views/theme/base/base.component';
import { ModuleGuard } from './services/module.guard';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./views/pages/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [ModuleGuard],
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'solicitacao',
        loadChildren: () =>
          import('./views/pages/solicitacoes/solicitacoes.module').then(
            (m) => m.SolicitacoesModule
          ),
      },
      {
        path: 'categoria',
        loadChildren: () =>
          import('./views/pages/categorias/categorias.module').then(
            (m) => m.CategoriasModule
          ),
      },
      {
        path: 'produto',
        loadChildren: () =>
          import('./views/pages/produtos/produtos.module').then(
            (m) => m.ProdutosModule
          ),
      },
      {
        path: 'cliente',
        loadChildren: () =>
          import('./views/pages/clientes/clientes.module').then(
            (m) => m.ClientesModule
          ),
      },
      {
        path: 'fornecedor',
        loadChildren: () =>
          import('./views/pages/fornecedores/fornecedores.module').then(
            (m) => m.FornecedoresModule
          ),
      },
      {
        path: 'perfil',
        loadChildren: () =>
          import('./views/pages/perfis/perfis.module').then(
            (m) => m.PerfisModule
          ),
      },
      {
        path: 'orcamento',
        loadChildren: () =>
          import('./views/pages/orcamento/orcamento.module').then(
            (m) => m.OrcamentoModule
          ),
      },
      {
        path: 'ordem',
        loadChildren: () =>
          import('./views/pages/ordens-compra/ordens-compra.module').then(
            (m) => m.OrdensCompraModule
          ),
      },
      {
        path: 'relatorios-categoria',
        loadChildren: () =>
          import('./views/pages/relatorios-categoria/relatorios-categoria.module').then(
            (m) => m.RelatoriosCategoriaModule
          ),
      },
      {
        path: 'relatorios-produto',
        loadChildren: () =>
          import('./views/pages/relatorios-produto/relatorios-produto.module').then(
            (m) => m.RelatoriosProdutoModule
          ),
      },
      {
        path: 'relatorios-oc',
        loadChildren: () =>
          import('./views/pages/relatorios-oc/relatorios-oc.module').then(
            (m) => m.RelatoriosOcModule
          ),
      },
      {
        path: 'relatorios-solicitacao',
        loadChildren: () =>
          import('./views/pages/relatorios-solicitacao/relatorios-solicitacao.module').then(
            (m) => m.RelatoriosSolicitacaoModule
          ),
      },


    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
