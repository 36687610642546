import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plate',
})
export class PlatePipe implements PipeTransform {
  transform(value: string) {
    if (value) {
      return value.substring(0, 3) + '-' + value.substring(3);
    }

    return value;
  }
}
