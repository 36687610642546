import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, retry, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PersistenceService, StorageType } from 'angular-persistence';

@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private persistenceService: PersistenceService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const saveToken =
      request.url.split('/')[0] != 'public' &&
      request.url.split('/')[0] != 'auth';

    if (saveToken) {
      const token =
        this.persistenceService.get('token', StorageType.LOCAL) ?? '';
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }

    request = request.clone({ url:request.url });

    return next.handle(request).pipe(
      filter((event) => event instanceof HttpResponse),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          switch (event.status) {
            case 201:
              this.snackBar.open('Dados cadastrados com sucesso', '', {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['success-snackbar'],
              });
              break;
          }
        }
      }),
      catchError((err) => {
        switch (err.status) {
          case 401:
            this.persistenceService.remove('token', StorageType.LOCAL);
            this.persistenceService.remove('user', StorageType.LOCAL);
            this.router.navigate(['auth']);
            break;
          default:
            this.snackBar.open(err.error.message || err.statusText, '', {
              duration: 3000,
              horizontalPosition: 'end',
              verticalPosition: 'top',
              panelClass: ['warn-snackbar'],
            });
            break;
        }

        return throwError(err.error.message || err.statusText);
      })
    );
  }
}
