<!-- begin:: Brand -->
<div class="brand flex-column-auto bg-dark-plus" [ngClass]="brandClasses">
  <!--begin::Logo-->
  <a href="javascript:void(0);" routerLink="/" class="brand-logo">
    <img alt="logo" [attr.src]="headerLogo" style="width: 90%;" />
  </a>
  <!--end::Logo-->

  <ng-container *ngIf="asideSelfMinimizeToggle">
    <!--begin::Toggle-->
    <button class="brand-toggle btn btn-sm px-0" (click)="toggleAsideClick()">
      <span class="svg-icon svg-icon-xl"
        [inlineSVG]="'./assets/media/svg/icons/Navigation/Angle-double-left.svg'"></span>
    </button>
    <!--end::Toolbar-->
  </ng-container>
</div>
<!-- end:: Brand -->