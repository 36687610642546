import { Deserializable } from './deserializable.model';
import { ModulesModel } from './modules.model';
import { Permissao } from './permissao.model';
import { Tipo } from './tipo.model';

export class CentroCusto implements Deserializable {
  id!: number;
  nome?: string;


  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
