import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ThemeModule } from './views/theme/theme.module';
import { LayoutConfigService } from './services/layout-config.service';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { moduleReducer } from 'src/app/store/module/modules.reducer';
import { userReducer } from 'src/app/store/user/user.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './views/pages/auth/services/auth.service';
import { PersistenceService } from 'angular-persistence';
import { ModuleGuard } from './services/module.guard';
import { AuthGuard } from './services/auth.guard';
import { LayoutConfig } from './views/shared/_config/layout.config';


export const reducers: ActionReducerMap<any> = {
  module: moduleReducer,
  user: userReducer,
};
export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ['module'],
    rehydrate: true,
  })(reducer);
}
export function initializeLayoutConfig(appConfig: LayoutConfigService): any {
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

export function initializeUser(as: AuthService): any {
  return () => {
    as.load();
  };
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,

    BrowserModule,
    AppRoutingModule,
    NgbModule,
    CommonModule,
    ThemeModule,
    NgxPermissionsModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),



  ],
  providers: [LayoutConfigService, NgxPermissionsService, AuthService, PersistenceService, ModuleGuard, AuthGuard,
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
    {
      // load usuario
      provide: APP_INITIALIZER,
      useFactory: initializeUser,
      deps: [AuthService],
      multi: true,
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }
