import { User } from '../../models/user.model';
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  Set = '[User] Set',
  Clear = '[User] Clear',
}

export const set = createAction(ActionTypes.Set, props<{ user: User }>());
export const clear = createAction(ActionTypes.Clear);
