<!-- begin:: Footer -->
<div class="footer bg-white py-4 d-flex flex-lg-column">
  <div class="d-flex flex-column flex-md-row align-items-center justify-content-between container-fluid">
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1">
      <span class="text-muted font-weight-bold mr-2">{{today | date:'yyyy'}} &copy;</span>
      <span class="text-black-50 font-weight-bold">Plus Compras</span>
    </div>
    <!--end::Copyright-->
  </div>
</div>
<!-- end:: Footer -->