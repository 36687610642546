import { ModulesTypes } from '../../models/modules.model';
import { createReducer, on } from '@ngrx/store';
import { change } from './modules.actions';

const INITIAL_STATE: any = ModulesTypes.None;

const _moduleReducer = createReducer(
  INITIAL_STATE,
  on(change, (state, { payload }) => payload)
);

export function moduleReducer(state: any, action: any): any {
  return _moduleReducer(state, action);
}
