import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { PersistenceService, StorageType } from 'angular-persistence';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private persistenceService: PersistenceService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.persistenceService.get('user', StorageType.LOCAL)) {
      return true;
    }
    // not logged in so redirect to login page
    this.router.navigate(['auth']);
    return false;
  }
}
