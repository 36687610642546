import { Component, OnInit } from '@angular/core';
import { HtmlClassService } from '../html-class.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  headerClasses = '';
  headerContainerClasses = '';

  constructor(public htmlClassService: HtmlClassService) {}

  ngOnInit(): void {
    this.headerClasses = this.htmlClassService
      .getClasses('header', true)
      .toString();

    this.headerContainerClasses = this.htmlClassService
      .getClasses('header_container', true)
      .toString();
  }
}
