import { PlatePipe } from './pipes/plate.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTreeModule } from '@angular/material/tree';
import { MatRadioModule } from '@angular/material/radio';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg';
// import { NgxPrintModule } from 'ngx-print';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { getTranslatePaginacaoIntl } from './_config/translate-paginacao';
import { InterceptService } from '../../services/intercept.service';

import {
  CgcPipe,
  DatePipe,
  FirstLetterPipe,
  PhonePipe,
  NumberPipe,
} from './pipes';
import {
  ContentAnimateDirective,
  MaskDateDirective,
  MenuDirective,
  OffcanvasDirective,
  ScrollTopDirective,
  ToggleDirective,
} from './directives';
import {
  AlertComponent,
  PortletModule,
  QuickActionComponent,
  ScrollTopComponent,
  UserProfileComponent,
} from './partials';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DateBrPipe } from './pipes/datebr.pipe';

export function getState(store: any) {
  let state: any;
  store.take(1).subscribe((o: any) => (state = o));
  return state;
}

registerLocaleData(localePt, 'pt-BR');

const mm = [
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatIconModule,
  MatFormFieldModule,
  MatSortModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatButtonModule,
  MatDialogModule,
  MatGridListModule,
  MatCardModule,
  MatInputModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatBadgeModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatMenuModule,
  MatRippleModule,
  MatStepperModule,
  MatTreeModule,
  MatRadioModule,
  NgxMatSelectSearchModule,
  MatIconModule,
  MatTabsModule,
];

@NgModule({
  imports: [
    ...mm,
    NgbDropdownModule,
    NgbTooltipModule,
    PortletModule,
    InlineSVGModule,
    CommonModule,
    FormsModule,
    // NgxPrintModule,
    AngularDualListBoxModule,
    ReactiveFormsModule,
  ],
  declarations: [
    CgcPipe,
    FirstLetterPipe,
    DatePipe,
    NumberPipe,
    PhonePipe,
    PlatePipe,
    DateBrPipe,

    ScrollTopDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    ContentAnimateDirective,
    MaskDateDirective,

    QuickActionComponent,
    ScrollTopComponent,
    UserProfileComponent,

    AlertComponent,
  ],
  exports: [
    CgcPipe,
    FirstLetterPipe,
    DatePipe,
    NumberPipe,
    PhonePipe,
    PlatePipe,
    DateBrPipe,

    ScrollTopDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    ContentAnimateDirective,
    MaskDateDirective,

    NgxMaskModule,
    NgxPermissionsModule,

    NgbDropdownModule,
    NgbTooltipModule,

    ScrollTopComponent,

    QuickActionComponent,
    ScrollTopComponent,
    UserProfileComponent,

    AlertComponent,

    PortletModule,

    // NgxPrintModule,
    AngularDualListBoxModule,
    ReactiveFormsModule,

    ...mm,
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
    NumberPipe,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MatPaginatorIntl, useValue: getTranslatePaginacaoIntl() },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class SharedModule {}
