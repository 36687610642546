import { createAction, props } from '@ngrx/store';
import { ModulesTypes } from '../../models/modules.model';

export enum ActionTypes {
  Change = '[Module] Change',
}

export const change = createAction(
  ActionTypes.Change,
  props<{ payload: ModulesTypes }>()
);
