// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { HtmlClassService } from '../../html-class.service';
import { LayoutConfigService } from '../../../../services/layout-config.service';
import { ToggleOptions } from '../../../shared/directives';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent implements OnInit {
  headerLogo = '';
  asideSelfDisplay = true;
  headerMobileClasses = '';

  toggleOptions: ToggleOptions = {
    target: KTUtil.getBody(),
    targetState: 'topbar-mobile-on',
    toggleState: 'active',
  };

  constructor(
    private layoutConfigService: LayoutConfigService,
    private uiService: HtmlClassService
  ) {}

  ngOnInit() {
    this.headerMobileClasses = this.uiService
      .getClasses('header_mobile', true)
      .toString();
    this.headerLogo = this.getLogoUrl();
    this.asideSelfDisplay = this.layoutConfigService.getConfig(
      'aside.self.display'
    );
  }

  getLogoUrl() {
    const headerSelfTheme =
      this.layoutConfigService.getConfig('header.self.theme') || '';
    const brandSelfTheme =
      this.layoutConfigService.getConfig('brand.self.theme') || '';
    let result = 'logo-light.png';
    if (!this.asideSelfDisplay) {
      if (headerSelfTheme === 'light') {
        result = 'logo-dark.png';
      }
    } else {
      if (brandSelfTheme === 'light') {
        result = 'logo-dark.png';
      }
    }
    return `./assets/media/logos/${result}`;
  }
}
