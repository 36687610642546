import { Component, OnInit } from '@angular/core';
import { HtmlClassService } from '../html-class.service';
import { LayoutConfigService } from '../../../services/layout-config.service';
import { LayoutConfigModel } from '../../../models/layout-config.model';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit {
  headerLogo = './assets/images/logotop.png';
  brandClasses = '';
  asideSelfMinimizeToggle = true;

  constructor(
    private layoutConfigService: LayoutConfigService,
    public htmlClassService: HtmlClassService
  ) {}

  ngOnInit(): void {
    this.brandClasses = this.htmlClassService
      .getClasses('brand', true)
      .toString();
    this.asideSelfMinimizeToggle = this.layoutConfigService.getConfig(
      'aside.self.minimize.toggle'
    );
  }

  toggleAsideClick() {
    let model: LayoutConfigModel = this.layoutConfigService.getConfig();
    model.aside.self.minimize.default = !model.aside.self.minimize.default;
    this.layoutConfigService.setConfig(model, true);
  }
}
