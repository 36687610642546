import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as objectPath from 'object-path';
import { LayoutConfigModel } from '../models/layout-config.model';
import { merge } from 'lodash';

const localStorageKey = 'layoutConfigV703';

@Injectable()
export class LayoutConfigService {
  onConfigUpdated$: Subject<LayoutConfigModel | null>;
  layoutConfig!: LayoutConfigModel | null;

  constructor() {
    this.onConfigUpdated$ = new Subject();
  }

  saveConfig(layoutConfig: LayoutConfigModel | null): void {
    if (layoutConfig) {
      localStorage.setItem(localStorageKey, JSON.stringify(layoutConfig));
    }
  }

  getSavedConfig(): LayoutConfigModel | null {
    const config = localStorage.getItem(localStorageKey);
    if (config) {
      try {
        return JSON.parse(config);
      } catch (e) {}
    }

    return null;
  }

  getConfig(path?: string): LayoutConfigModel | any {
    this.layoutConfig = this.getSavedConfig();

    if (path && this.layoutConfig) {
      // if path is specified, get the value within object
      return objectPath.get(this.layoutConfig, path);
    }

    return this.layoutConfig;
  }

  loadConfigs(config: LayoutConfigModel) {
    this.layoutConfig = this.getSavedConfig();

    if (!this.layoutConfig) {
      this.layoutConfig = config;
    }

    this.saveConfig(this.layoutConfig);
  }

  setConfig(value: any, save?: boolean): void {
    this.layoutConfig = merge(this.layoutConfig, value);

    if (save) {
      this.saveConfig(this.layoutConfig);
    }

    this.onConfigUpdated$.next(this.layoutConfig);
  }
}
