import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import {
  NgbProgressbarModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { InlineSVGModule } from 'ng-inline-svg';
import { HeaderComponent } from './header/header.component';
import { AsideLeftComponent } from './aside/aside-left.component';
import { FooterComponent } from './footer/footer.component';
import { BrandComponent } from './brand/brand.component';
import { TopbarComponent } from './header/topbar/topbar.component';
import { BaseComponent } from './base/base.component';
import { HtmlClassService } from './html-class.service';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import { SharedModule } from '../shared/shared.module';
import { BreadcrumbsComponent } from './header/breadcrumbs/breadcrumbs.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    BaseComponent,
    FooterComponent,
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,
    TopbarComponent,
    AsideLeftComponent,
    BreadcrumbsComponent,
  ],
  exports: [
    BaseComponent,
    FooterComponent,
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,
    TopbarComponent,
    AsideLeftComponent,
  ],
  providers: [HtmlClassService],
  imports: [
    CommonModule,
    RouterModule,
    PerfectScrollbarModule,
    FormsModule,
    InlineSVGModule,
    BrowserModule,
    NgbProgressbarModule,
    NgbTooltipModule,

    SharedModule,
  ],
})
export class ThemeModule {}
