<!--begin: Quick actions -->
<div ngbDropdown placement="bottom-right" autoClose="outside" class="dropdown dropdown-fluid">
	<!--begin::Toggle-->
	<div ngbDropdownToggle class="topbar-item">
		<div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
			<ng-container>
				<span class="svg-icon svg-icon-xl svg-icon-plusfrota"
					[inlineSVG]="'./assets/media/svg/icons/Layout/Layout-4-blocks.svg'"></span>
			</ng-container>
		</div>
	</div>
	<!--end::Toggle-->

	<!--begin::Dropdown-->
	<!-- <div ngbDropdownMenu
		class="dropdown-menu p-0 m-0 dropdown-menu-fit dropdown-menu-right dropdown-menu-anim-up dropdown-menu-xl">
		<form>
		
			<div class="d-flex flex-column flex-center py-10 bgi-size-cover bgi-no-repeat rounded-top"
				style="background-color: #131f26">
				<h4 class="text-white font-weight-bold">Módulos</h4>
				<span class="btn btn-plusfrota btn-sm font-weight-bold font-size-sm mt-2">{{(user$ |
					async)?.modules?.length}} disponíveis</span>
			</div>
	
			<div class="row row-paddingless">
		
				<div class="col-6" *ngFor="let mod of (user$ | async)?.modules">
					<a href="javascript:void(0);"
						class="d-block py-10 px-5 text-center bg-hover-light border-right border-bottom"
						>
						<i class="{{mod.icon}} icone"></i>
						<span class="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">{{mod.name}}</span>
					</a>
				</div>
	
			</div>
		</form>
	</div> -->

</div>
<!--end: Quick actions -->