import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import * as objectPath from 'object-path';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModulesTypes } from '../../../models/modules.model';
import { LayoutConfigService } from '../../../services/layout-config.service';
import { MenuOptions, OffcanvasOptions } from '../../shared/directives';
import { HtmlClassService } from '../html-class.service';

export interface PageMenu {
  section?: string | boolean;
  title?: string;
  root?: boolean;
  icon?: string;
  page?: string;
  alignment?: string;
  toggle?: string;
  submenu?: PageMenu[];
  download?: boolean;
  separator?: boolean;
}

@Component({
  selector: 'app-aside-left',
  templateUrl: './aside-left.component.html',
  styleUrls: ['./aside-left.component.scss'],
})
export class AsideLeftComponent implements OnInit {
  module$: Observable<ModulesTypes>;

  @ViewChild('asideMenuOffcanvas', { static: true })
  asideMenuOffcanvas!: ElementRef;
  @ViewChild('asideMenu', { static: true }) asideMenu!: ElementRef;

  asideClasses = '';
  currentRouteUrl = '';
  insideTm: any;
  outsideTm: any;

  menuCanvasOptions: OffcanvasOptions = {
    baseClass: 'aside',
    overlay: true,
    closeBy: 'kt_aside_close_btn',
    toggleBy: {
      target: 'kt_aside_mobile_toggle',
      state: 'mobile-toggle-active',
    },
  };

  menuOptions: MenuOptions = {
    // submenu setup
    submenu: {
      desktop: {
        // by default the menu mode set to accordion in desktop mode
        default: 'dropdown',
      },
      tablet: 'accordion', // menu set to accordion in tablet mode
      mobile: 'accordion', // menu set to accordion in mobile mode
    },

    // accordion setup
    accordion: {
      expandAll: false, // allow having multiple expanded accordions in the menu
    },
  };

  menusDash: PageMenu[] = [
    {
      title: 'Dashboard',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      page: '/dashboard',
      section: false,
      separator: false,
      download: false,
    },
  ];
  menusModule: PageMenu[] = [];
  menus: PageMenu[] = [];

  constructor(
    public htmlClassService: HtmlClassService,
    public layoutConfigService: LayoutConfigService,
    private router: Router,
    private render: Renderer2,
    private permissionsService: NgxPermissionsService,
    private store: Store<{ module: ModulesTypes }>
   ) 
   {
    this.module$ = this.store.pipe(select('module'));
    this.module$.subscribe((module) => {
      let permissions = this.permissionsService.getPermissions();
      if (permissions.ADMINISTRADOR) {
        this.menus = [
          { section: 'Administrativo' },
          {
            title: 'Clientes',
            root: true,
            icon: 'fas fa-building',
            page: '/cliente',
          },
          {
            title: 'Fornecedores',
            root: true,
            icon: 'fas fa-car-bus',
            page: '/fornecedor',
          },
        ];
      } else if(permissions.CLIENTE){
        this.menus = [
          { section: 'Cadastro' },
          {
            title: 'Categorias',
            root: true,
            icon: 'fas fa-archive',
            page: '/categoria',
          },
          {
            title: 'Produtos',
            root: true,
            icon: 'fas fa-box',
            page: '/produto',
          },
          {
            title: 'Fornecedores',
            root: true,
            icon: 'fas fa-users',
            page: '/fornecedor',
          },
          // {
          //   title: 'Perfis',
          //   root: true,
          //   icon: 'fas fa-user-alt',
          //   page: '/perfil',
          // },
          { section: 'Cotações' },
          {
            title: 'Solicitações',
            root: true,
            icon: 'far fa-siren',
            page: '/solicitacao',
          },
          {
            title: 'Ordens de compra',
            root: true,
            icon: 'far fa-money-check-edit',
            page: '/ordem',
          },
          { section: 'Relatórios' },
          {
            title: 'Categorias',
            root: true,
            icon: 'far fa-archive',
            page: '/relatorios-categoria',
          },
          {
            title: 'Produtos',
            root: true,
            icon: 'far fas fa-box',
            page: '/relatorios-produto',
          },
          {
            title: 'Solicitações de Orçamento',
            root: true,
            icon: 'far fa-siren',
            page: '/relatorios-solicitacao',
          },
          {
            title: 'Ordens de Compra',
            root: true,
            icon: 'far fa-money-check-edit',
            page: '/relatorios-oc',
          },

        ];
      }else if(permissions.FORNECEDOR){
        this.menus = [
          { section: 'Cotações' },
          {
            title: 'Solicitações de orçamento',
            root: true,
            icon: 'far fa-siren',
            page: '/solicitacao',
          },
          {
            title: 'Meus Orçamentos',
            root: true,
            icon: 'far fa-siren',
            page: '/orcamento',
          },
        ];
      }
    });
  }
  //  {
  //   this.module$ = this.store.pipe(select('module'));
  //   this.module$.subscribe((module) => {
  //     let permissions = this.permissionsService.getPermissions();

  //     if (permissions.ADMINISTRADOR) {
  //       this.menus = [
  //         { section: 'Administrativo' }
  //       ];
  //       if (permissions.LISTARFORNECEDOR) {
  //         this.menus.push(
  //           {
  //             title: 'Fornecedores',
  //             root: true,
  //             icon: 'fas fa-car-bus',
  //             page: '/fornecedor',
  //           })
  //       }
  //       if (permissions.LISTARCLIENTE) {
  //         this.menus.push(
  //           {
  //             title: 'Clientes',
  //             root: true,
  //             icon: 'fas fa-building',
  //             page: '/cliente',
  //           })
  //       }
  //     }
  //     if ((permissions.LISTARCATEGORIA || permissions.LISTARPRODUTO || permissions.LISTARFORNECEDOR) && permissions.CLIENTE) {
  //       this.menus = [
  //         { section: 'Cadastro' },
  //       ];
  //       if (permissions.LISTARCATEGORIA) {
  //         this.menus.push({
  //           title: 'Categorias',
  //           root: true,
  //           icon: 'fas fa-archive',
  //           page: '/categoria',
  //         }
  //         )
  //       }
  //       if (permissions.LISTARPRODUTO) {
  //         this.menus.push({
  //           title: 'Produtos',
  //           root: true,
  //           icon: 'fas fa-box',
  //           page: '/produto',
  //         }
  //         )
  //       }
  //       if(permissions.LISTARFORNECEDOR){
  //         this.menus.push({
  //           title: 'Fornecedores',
  //           root: true,
  //           icon: 'fas fa-users',
  //           page: '/fornecedor',
  //         },
  //        )
  //       }
  //       if((permissions.LISTARSOLICITACAO || permissions.LISTAROC) && permissions.CLIENTE){
  //         this.menus.push(
  //           { section: 'Cotações' },
  //           )
  //           if(permissions.LISTARSOLICITACAO){
  //             this.menus.push({
  //               title: 'Solicitações',
  //               root: true,
  //               icon: 'far fa-siren',
  //               page: '/solicitacao',
  //             })
  //           }
  //           if(permissions.LISTAROC){
  //             this.menus.push(
  //               {
  //                 title: 'Ordens de compra',
  //                 root: true,
  //                 icon: 'far fa-money-check-edit',
  //                 page: '/ordem',
  //               })
  //           }
  //       }
        
  //       if(permissions.GERARRELATORIO && permissions.CLIENTE){
  //         this.menus.push(
  //           { section: 'Relatórios' },
  //           {
  //             title: 'Categorias',
  //             root: true,
  //             icon: 'far fa-archive',
  //             page: '/relatorios-categoria',
  //           },
  //           {
  //             title: 'Produtos',
  //             root: true,
  //             icon: 'far fas fa-box',
  //             page: '/relatorios-produto',
  //           },
  //           {
  //             title: 'Solicitações de Orçamento',
  //             root: true,
  //             icon: 'far fa-siren',
  //             page: '/relatorios-solicitacao',
  //           },
  //           {
  //             title: 'Ordens de Compra',
  //             root: true,
  //             icon: 'far fa-money-check-edit',
  //             page: '/relatorios-oc',
  //           },)
  //       }
  //     } else
  //     if ((permissions.LISTARSOLICITACAO || permissions.LISTAROC) && permissions.FORNECEDOR) {
  //       this.menus = [
  //         { section: 'Cotações' },
         
  //       ];
  //       if(permissions.LISTARSOLICITACAO){
  //         this.menus.push( {
  //           title: 'Solicitações de orçamento',
  //           root: true,
  //           icon: 'far fa-siren',
  //           page: '/solicitacao',
  //         },
  //        );
  //       }
  //       if(permissions.LISTAROC){
  //         this.menus.push( {
  //           title: 'Meus Orçamentos',
  //           root: true,
  //           icon: 'far fa-siren',
  //           page: '/orcamento',
  //         },)
  //       }
  //     }
  //   });
  // }

  ngOnInit() {
    this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
      });

    const config = this.layoutConfigService.getConfig();

    if (objectPath.get(config, 'aside.menu.dropdown')) {
      this.render.setAttribute(
        this.asideMenu.nativeElement,
        'data-ktmenu-dropdown',
        '1'
      );
      this.render.setAttribute(
        this.asideMenu.nativeElement,
        'data-ktmenu-dropdown-timeout',
        objectPath.get(config, 'aside.menu.submenu.dropdown.hover-timeout')
      );
    }

    this.asideClasses = this.htmlClassService
      .getClasses('aside', true)
      .toString();
  }

  isMenuItemIsActive(item: PageMenu): boolean {
    if (item.submenu) {
      return this.isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }

    return this.currentRouteUrl.startsWith(item.page);
  }

  isMenuRootItemIsActive(item: PageMenu): boolean {
    let result = false;

    for (const subItem of item.submenu ?? []) {
      result = this.isMenuItemIsActive(subItem);
      if (result) {
        return true;
      }
    }

    return false;
  }

  mouseEnter() {
    // check if the left aside menu is fixed
    if (document.body.classList.contains('aside-fixed')) {
      if (this.outsideTm) {
        clearTimeout(this.outsideTm);
        this.outsideTm = null;
      }

      this.insideTm = setTimeout(() => {
        // if the left aside menu is minimized
        if (
          document.body.classList.contains('aside-minimize') &&
          KTUtil.isInResponsiveRange('desktop')
        ) {
          // show the left aside menu
          this.render.removeClass(document.body, 'aside-minimize');
          this.render.addClass(document.body, 'aside-minimize-hover');
        }
      }, 50);
    }
  }

  mouseLeave() {
    if (document.body.classList.contains('aside-fixed')) {
      if (this.insideTm) {
        clearTimeout(this.insideTm);
        this.insideTm = null;
      }

      this.outsideTm = setTimeout(() => {
        // if the left aside menu is expand
        if (
          document.body.classList.contains('aside-minimize-hover') &&
          KTUtil.isInResponsiveRange('desktop')
        ) {
          // hide back the left aside menu
          this.render.removeClass(document.body, 'aside-minimize-hover');
          this.render.addClass(document.body, 'aside-minimize');
        }
      }, 100);
    }
  }

  getItemCssClasses(item: PageMenu) {
    let classes = 'menu-item';

    if (objectPath.get(item, 'submenu')) {
      classes += ' menu-item-submenu';
    }

    if (!item.submenu && this.isMenuItemIsActive(item)) {
      classes += ' menu-item-active menu-item-here';
    }

    if (item.submenu && this.isMenuItemIsActive(item)) {
      classes += ' menu-item-open menu-item-here';
    }

    // custom class for menu item
    const customClass = objectPath.get(item, 'custom-class');
    if (customClass) {
      classes += ' ' + customClass;
    }

    if (objectPath.get(item, 'icon-only')) {
      classes += ' menu-item-icon-only';
    }

    return classes;
  }

  getItemAttrSubmenuToggle(item: PageMenu) {
    let toggle = 'hover';
    if (objectPath.get(item, 'toggle') === 'click') {
      toggle = 'click';
    } else if (objectPath.get(item, 'submenu.type') === 'tabs') {
      toggle = 'tabs';
    } else {
      // submenu toggle default to 'hover'
    }

    return toggle;
  }

  baixar(item: string) {
    const downloadLink = document.createElement('a');
    const fileName = 'Manual PlusFrota';

    downloadLink.setAttribute('type', 'hidden');

    downloadLink.href = item;
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);

    downloadLink.click();
    downloadLink.remove();
  }
}
