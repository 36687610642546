import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date',
})
export class DatePipe implements PipeTransform {
  transform(date: any, args?: any): any {
    let dateformat = '';

    if (date != undefined) {
      if (args == 'DD/MM/YYYY') {
        dateformat =
          date.substring(8, 10) +
          '/' +
          date.substring(5, 7) +
          '/' +
          date.substring(0, 4);
      }

      if (args == 'HH:mm') {
        dateformat = date.substring(11, 16);
      }

      if (args == 'HH:mm:ss') {
        dateformat = date.substring(11, 19);
      }

      if (args == 'DD/MM/YYYY HH:mm') {
        dateformat =
          date.substring(8, 10) +
          '/' +
          date.substring(5, 7) +
          '/' +
          date.substring(0, 4) +
          ' ' +
          date.substring(11, 16);
      }
    }

    return dateformat;
  }
}
