import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/views/pages/auth/services/auth.service';
import { User } from '../../../../../../models/user.model';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent {
  user$: Observable<User>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<{ user: User }>
  ) {
    this.user$ = this.store.pipe(select('user'));
  }
  logout(): void {
    this.authService.logout();
    this.router.navigate(['auth']);
  }
  
}
