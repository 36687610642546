import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { User} from '../../../../../../models/user.model';

@Component({
  selector: 'app-quick-action',
  templateUrl: './quick-action.component.html',
  styleUrls: ['quick-action.component.scss'],
})
export class QuickActionComponent {
  user$: Observable<User>;

  @ViewChild(NgbDropdown) private dropdown!: NgbDropdown;

  constructor(
    private store: Store<{ user: User }>,
    private router: Router
  ) {
    this.user$ = this.store.select('user');
  }

  
}
