import { Deserializable } from './deserializable.model';
import { ModulesModel } from './modules.model';
import { Permissao } from './permissao.model';
import { Tipo } from './tipo.model';

export class Perfil implements Deserializable {
  id!: number;
  nome?: string;
  tipo?: Tipo;

  permissoes: Permissao[] = [];

  deserialize(input: any): this {
    input.permissoes = input.permissoes.map((mod: any) =>
      new Permissao().deserialize(mod)
    );
    Object.assign(this, input);
    return this;
  }
}
