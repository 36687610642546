// Angular
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { PortletBodyComponent } from './portlet-body.component';
import { PortletHeaderComponent } from './portlet-header.component';
import { PortletFooterComponent } from './portlet-footer.component';

export interface PortletOptions {
  test?: any;
}

@Component({
  selector: 'app-portlet',
  templateUrl: './portlet.component.html',
  exportAs: 'ktPortlet',
})
export class PortletComponent {
  // Public properties
  @Input() loading$!: Observable<boolean>;
  // portlet extra options
  @Input() options!: PortletOptions;
  // portlet root classes
  @Input() class!: string;

  @ViewChild('portlet', { static: true }) portlet!: ElementRef;

  // portlet header component template
  @ViewChild(PortletHeaderComponent, { static: true })
  header!: PortletHeaderComponent;
  // portlet body component template
  @ViewChild(PortletBodyComponent, { static: true })
  body!: PortletBodyComponent;
  // portlet footer component template
  @ViewChild(PortletFooterComponent, { static: true })
  footer!: PortletFooterComponent;
}
